import React from "react"
import PageLayout from "../components/page-layout"
import { SectionLayout, ShortHeroSection, Career } from "../components/sections"

import { HERO_SECTION_ID, CAREER } from "../constants"

export default function CareerPage() {
  return (
    <PageLayout>
      <ShortHeroSection
        sectionId={HERO_SECTION_ID}
        sectionTitle="Home / Career"
      />
      <SectionLayout
        noFullHeight
        sectionId={CAREER}
        sectionTitle="Careet At Emelord Security"
      >
        <Career />
      </SectionLayout>
    </PageLayout>
  )
}
